import { type ReactNode } from 'react';

import { Tooltip } from '@/components/shared/Tooltip';
import type { Nullable } from '@/types/shared';

import style from './AddressLines.module.sass';

type AddressLinesProps = {
  addressLine?: Nullable<string>;
  placeholder?: ReactNode;
};

const DEFAULT_MAX_LINES = 3;

export const AddressLines = ({
  addressLine,
  placeholder,
}: AddressLinesProps) => {
  const addressToLines = (string: string) => {
    return string.split(',').filter(Boolean);
  };

  if (!addressLine) {
    return placeholder ? <>{placeholder}</> : null;
  }

  const lines = addressToLines(addressLine);
  const isAddressNotSuperLongButStillLongEnough = lines.length > 3;

  const renderLine = (
    line: string,
    index: number,
    tooltipIndicator?: string,
  ) => {
    const shouldShowDots = index + 1 === DEFAULT_MAX_LINES;

    const renderDots = () => {
      if (tooltipIndicator && shouldShowDots) {
        return (
          <>
            {' '}
            {shouldShowDots && '...'}
          </>
        );
      }
    };

    return (
      <p className={style.line} key={index}>
        {line.trim()}
        {renderDots()}
      </p>
    );
  };

  const renderTooltipLine = (line: string, index: number) => {
    return (
      <p className={style.tooltipLine} key={index}>
        {line.trim()}
      </p>
    );
  };

  if (isAddressNotSuperLongButStillLongEnough) {
    const slicedLines = lines.slice(0, 3);

    return (
      <Tooltip
        content={
          <div>
            {lines.map(renderTooltipLine)}
          </div>
        }>
        <div>
          {slicedLines.map((line, index) => renderLine(line, index, '...'))}
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      {lines.map((line, index) => renderLine(line, index))}
    </>
  );
};
